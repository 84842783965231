(function ($) {
    $(document).ready(function () {

    	/* MOBILE NAV */
        // Toggle Mobile Nav
        $('#mobNavTrg').click(function(e) {
            e.preventDefault();
            $('body').toggleClass('mini');
        });

        $('#mobile-menu .sub-menu').each(function() {
            var parentlink = $(this).parent().find('a').attr('href');
            var parentname = $(this).parent().find('a').html();
            if (parentlink != '#' && parentlink != '' && parentlink != $(this).find('li').first().children('a').attr('href')) {
                // add parent link to secondary page if link is not in sub menu
                $(this).prepend('<li><a class="parentlink" href="' + parentlink + '">' + parentname + '</a></li>');
            }
            $(this).append('<li><a class="goback">go back</a></li>');
        });

        $('#mobile-menu').append('<li><a class="close-mobile">close menu</a></li>');

        $('#mobile-menu .menu-item-has-children > a').on('click', function(e) {
            e.preventDefault();
            $(this).next('.sub-menu').toggleClass('triggered');
        });

        $('.goback').on('click', function(e) {
            e.preventDefault();
            $(this).closest('.sub-menu').toggleClass('triggered');
        });

        $('.close-mobile').on('click', function(e) {
            e.preventDefault();
            $(this).parents('body').toggleClass('mini');
        });
        /* END -- MOBILE NAV */ 
        
        // homepage header images
        $('#homeSlider').slick({
			infinite: true,
			dots: true,
			autoplay: true,
			fade: true,
			autoplaySpeed: 6500,
            arrows: false
        });
        
        // homepage featured listings
        $('.feat-prop-slider').slick({
            infinite: true,
			dots: false,
            arrows: true,
            slidesToShow: 3,
            prevArrow: '<button type="button" class="slick-prev-d3"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>',
            nextArrow: '<button type="button" class="slick-next-d3"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>',
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 675,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 460,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                        dots: true
                    }
                },
            ]
        });

        // omnibar placeholders
        $('.IDX_Omnibar_Widget .idx-omnibar-extra-input').attr('placeholder', 'City, Zip, or Address');
		$('.IDX_Omnibar_Widget .idx-omnibar-bed').attr('placeholder', 'Beds');
		$('.IDX_Omnibar_Widget .idx-omnibar-bath').attr('placeholder', 'Baths');
		$('.IDX_Omnibar_Widget .idx-omnibar-min-price').attr('placeholder', 'Min $');
        $('.IDX_Omnibar_Widget .idx-omnibar-price').attr('placeholder', 'Max $');
		
    });

    // equal height items
    $('.col-eq-height, .impress-showcase-property').matchHeight();
    
})(jQuery);
